var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        { attrs: { type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 14, lg: 16 } },
            [_c("a-row", { attrs: { type: "flex", gutter: 10 } })],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-table-full gx-table-responsive",
                  attrs: { title: "Members" },
                },
                [
                  _c("ClubMemberTableAdmin", {
                    ref: "club_member_Listing",
                    attrs: { filters: _vm.filters },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }